/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "./theme/theme.scss";

html,
body {
    height: 100%;
    width: 100%;
    position: fixed;
}
body {
    margin: 0;
    font-family: "Work Sans", Roboto, "Helvetica Neue", sans-serif;
    font-size: small;
}

.mat-custom {
    .mat-stepper-horizontal {
        width: 100%;
    }
    .mat-form-field-label {
        letter-spacing: 0.1px;
    }
}

.page-container {
    /*
    @extend .mat-custom;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    max-width: 400px;
    align-items: center;
    margin: auto;
*/
align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 400px;
    align-items: center;
    margin: auto;

}

.main-toolbar {
    // overflow: hidden;
    // height: 120px;
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    // flex-direction: row;
    // flex-grow: 0;
    max-width: 400px;
    top: 0;
    z-index: 10;

    position: -webkit-sticky; /* Safari */
    position: sticky;
}

.main-tab-bar {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    max-width: 400px;
}

.custom-dialog-container .mat-dialog-container {
    overflow-y: hidden;
}

.no-round {
    border-radius: 0 !important;
}

.round {
    border-radius: 10px !important;
}

.primary-filters {
    -webkit-flex-direction: column;
    flex-direction: column;
    display: flex;
}
.details-filters {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
.filters-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.flex-container-old {
    width: 96%;
    overflow-y: auto;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-around;
    max-width: 640px;
    margin-left: 2%;
    margin-top: 10px;
}

.flex-container {
    overflow-y: auto;
    display: -webkit-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    justify-content: space-between;
    padding: 15px;
    flex-direction:row;
    flex-wrap: wrap;
}

.flex-container-legacy {
    overflow-y: auto;
    // display: -webkit-box;
    display: flex;
    flex-flow: row wrap;
    //justify-content: center;
    justify-content: space-between;
    padding: 10px;
}


.page-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    justify-content: center;
    max-width: 400px;
    // margin-top:-14px;
    // margin-bottom: -14px;
    // margin-left: 1px;

}

.no-scroll {
    overflow-y: hidden;
}

.no-results-msg {
    text-align: center;
    font-size: medium;
    font-weight: bold;
    margin: 30px 0;
}

.vspan-5 {
    width: 5px;
}
.vspan-10 {
    height: 30px;
}

.width-5 {
    width: 5%;
}

.width-10 {
    width: 10%;
}
.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-28 {
    width: 28%;
}

.width-30 {
    width: 30%;
}

.width-33 {
    width: 33%;
}

.width-35 {
    width: 35%;
}

.width-40 {
    width: 40%;
}

.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-70 {
    width: 70%;
}

.width-75 {
    width: 75%;
}

.width-77 {
    width: 77%;
}

.width-80 {
    width: 80%;
}

.width-85 {
    width: 85%;
}

.width-90 {
    width: 90%;
}

.width-95 {
    width: 95%;
}

.width-100 {
    width: 100%;
}

.height-5 {
    height: 5%;
}

.height-15 {
    height: 15%;
}

.height-20 {
    height: 20%;
}

.height-25 {
    height: 25%;
}

.height-28 {
    height: 28%;
}

.height-30 {
    height: 30%;
}

.height-33 {
    height: 33%;
}

.height-35 {
    height: 35%;
}

.height-40 {
    height: 40%;
}

.height-50 {
    height: 50%;
}

.height-60 {
    height: 60%;
}

.height-75 {
    height: 75%;
}

.height-77 {
    height: 77%;
}

.height-100 {
    height: 100%;
}

.form-group,
.input-group {
    margin-bottom: 10px;
    position: relative;
}

.input-box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    height: auto;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    font-weight: 400;
}

//TODO: move to theme
.mat-snack-bar-container {
    &.success {
        color: rgba(255, 255, 255, 0.7);
        background-color: #323232;
        .mat-button {
            color: rgba(255, 255, 255, 0.7);
        }
    }
    &.warning {
        color: #856404;
        background-color: #fff3cd;
        .mat-button {
            color: #856404;
        }
    }
    &.error {
        color: #721c24;
        background-color: #f8d7da;
        .mat-button {
            color: #721c24;
        }
    }
}

.timepicker-overlay {
    z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
    z-index: 1100 !important;
}

.ngx-progressbar {
    width: 100%;

    .p-progressbar {
        height: 16px;
        border-radius: 8px;

        background: #b9e6ea;
    }

    .p-progressbar .p-progressbar-value {
        height: 16px;
        border-radius: 8px;

        background: #14aab9;
    }
}

.ngx-overlaypanel {
    .p-overlaypanel {
        background: #ffffff;
    }
    .p-overlaypanel .p-overlaypanel-close {
        background: #14aab9;
    }
}

.ngx-card-track {
    .p-card-title {
        font-size: 1.2rem !important;
    }

    .p-card-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0px !important;
    }
}

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.no-item-label {
    margin-top: 40px;
    text-align: center;
    font-size: medium;
}

.card-list {
    overflow-y: auto;
}

.card-list-item {
    display: flex;
    flex-direction: row;

    width: 100%;

    .card-list-item-text {
        flex-grow: 1;
        margin-bottom: auto;
        margin-top: auto;
    }

    .card-list-item-action {
    }
}

.card {
    display: flex;
    flex-direction: column;
    height: 500px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(75 107 162 / 40%);

    .card-header {
        margin-top: -20px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px;
        border-radius: 5px;
        font-size: x-large;
        color: white;
        box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(75 107 162 / 40%);
        background-color: #4b6ba2;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;

        padding: 20px;
    }

    .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        margin-right: 10px;
        margin-bottom: 10px;
    }
}
/*
.space-5 {
    height:5px;
}
.space-8 {
    height:8px;
}
.space-10 {
    height:10px;
}
.space-20 {
    height:20px;
}

::-webkit-scrollbar {
    width: 16px;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }

*/

